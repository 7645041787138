import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {


  return (
    <footer>
      <div className="hidden w-full p-6 font-lato md:flex flex-row justify-center gap-12">
        <div className='flex-grow'>
          <h1 className="logo">ALDIRAC</h1>
          <p className="italic text-gray-400">
            be good.
          </p>
        </div>
        <div>
          <p className="text-lg text-gray-200">Links</p>
          <ul>
            <li>
              <Link className="text-sm" spy={true} smooth={true} duration={1000} to="/"> Home </Link>
            </li>
            <li>
              <Link className="text-sm" to="services" spy={true} smooth={true} duration={1000} > Services </Link>
            </li>
            <li>
              <Link className="text-sm" to="about-scroll" spy={true} smooth={true} duration={1000}>About Us  </Link>
            </li>
            <li>
              <Link className="text-sm" to="contact" spy={true} smooth={true} duration={1000}> Contact  </Link>
            </li>
          </ul>
        </div>
        <div>
          <p className="text-lg text-gray-200">Social Media</p>
          <ul>
            <li >
              <a className="text-sm" target="_blank" rel="noreferrer" href="https://github.com/aldirac" > Github</a>
            </li>
            <li>
              <a className="text-sm" target="_blank" rel="noreferrer" href="https://twitter.com/aldirac" > Twitter</a>
            </li>
            <li>
              <a className="text-sm" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/aldirac/"> Linkedin</a>
            </li>
          </ul>
        </div>
        <div >
          <p className="text-lg text-gray-200">Contact</p>
          <ul>
            <li>
              <Link className="text-sm" to="#" >contact@aldirac.com</Link>
            </li>
          </ul>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
