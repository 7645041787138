function Card(props) {
  return (
    <div className="backdrop-filter bg-opacity-70 backdrop-blur-xl flex flex-col place-items-start items-start h-full  px-3 lg:px-7 py-6 bg-white rounded-xl leading-relaxed md:mx-auto">
        <div className="p-3 rounded-xl w-full bg-black/80 backdrop-blur-3xl text-2xl font-bold text-gray-200">
          {props.title}
        </div>
      <div>
        <div className="space-y-2 p-3">
          <p className="text-slate-800">{props.text}</p>
        </div>
      </div>
    </div>
  );
}
export default Card;
