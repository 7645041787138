const services = [
  {
    type: "Frontend Developers",
    img: "",
    description:
      "Our talent teams for frontend developers include sourcing candidates with expertise in popular frontend technologies such as React, Vue, and Angular. We also prioritize candidates with a keen eye for design and a deep understanding of user experience.",
  },
  {
    type: "Backend Developers",
    img: "",
    description:
      "Our talented backend developers possess a strong understanding of backend technologies such as Node.js, Python, and Ruby on Rails. We look for candidates who have experience building scalable, secure, and high-performing applications.",
  },
  {
    type: "Full-Stack Developers",
    img: "",
    description:
      "For full-stack developers, we look for candidates who have a deep understanding of both frontend and backend technologies. We prioritize candidates who have experience building end-to-end applications and can seamlessly integrate frontend and backend components.",
  },
  {
    type: "Mobile Developers",
    img: "",
    description:
      "Our talent teams for mobile app developers include sourcing candidates with expertise in developing iOS and Android apps. We look for candidates who have experience building apps with clean, maintainable code and a strong focus on performance.",
  },
  {
    type: "DevOps Engineers",
    img: "",
    description:
      "For DevOps engineers, we prioritize candidates with expertise in technologies such as Docker, Kubernetes, and Jenkins. We look for candidates who have experience building and maintaining scalable, automated infrastructure and deployment pipelines.",
  },
  {
    type: "Product Manager",
    img: "",
    description:
      "Our product manager talent teams source candidates skilled in product strategy, launching new products, market research, and cross-functional collaboration. We seek individuals with a strong user-centered mindset and a passion for delivering exceptional experiences.",
  },
  {
    type: "UX Designers",
    img: "",
    description:
      "Our talent teams for UX designers include sourcing candidates with a strong background in user research, information architecture, and user interface design. We prioritize candidates who have experience creating intuitive, user-centered designs for web and mobile applications.",
  },
  {
    type: "Data Scientists",
    img: "",
    description:
      "Our talent teams for data scientists include sourcing candidates with experience in machine learning, statistical modeling, and data visualization. We prioritize candidates who have experience working with large datasets and can effectively communicate insights to stakeholders.",
  },
  {
    type: "Cloud Engineers",
    img: "",
    description:
      "For cloud engineers, we prioritize candidates with expertise in cloud platforms such as AWS, Azure, and Google Cloud. We look for candidates who have experience designing, implementing, and managing cloud-based infrastructure and services.",
  },
];
export default services;
