import React, { useEffect } from "react";
import "../style/home.css";
import gsap from "gsap";

function About() {
  useEffect(() => {
    gsap.from(".title", {
      opacity: 0,
    });
    gsap.to(".title", {
      ease: "power4.out",
      duration: 2,
      opacity: 1,
      stagger: {
        amount: 1,
      },
    });
    gsap.fromTo(".about-card", {opacity:0, x:-50},{
      ease: "circ.out",
      x:0,
      delay:1,
      duration: 2,
      opacity: 1,
      stagger: {
        amount: 1,
      },
    });
    gsap.to(".navbar", {
    });
  }, []);

  return (
    <>
      <div className="font-lato pt-32 pb-10 header">
        <div className="font-medium text-center text-lg font-lato">
          <div className="text-gray-700 text-lg p-6">
            <div className="flex flex-col">
              <h1 className="title font-extrabold text-3xl lg:text-5xl uppercase pb-16 text-gray-200">
                Providing Scalable Teams since 2010
              </h1>
              <div className="flex align-top mx-auto bg-white rounded-lg shadow-md">
                <div className="flex-col flex-wrap">
                  <div className="font-medium text-xl md:text-3xl p-3">
                    {" "}
                    We appreciate your interest in getting to know us
                  </div>
                  <div className="text-base max-w-xl p-5 md:inline-flex align-top text-start">
                    <div className="font-extrabold text-5xl float-left mx-3 my-0">
                      01.
                    </div>
                    <div>
                      We are a group of highly motivated college graduates who
                      recognized the need for a reliable and efficient talent
                      service in the tech industry. With our extensive
                      experience in the field, we created this platform to
                      provide our clients with the best possible talent
                      solutions for their development and product needs.
                    </div>
                    <div>
                      <img
                        alt=""
                        src="img/card1.png"
                        className="max-w-[15rem] hidden md:flex"
                      />
                    </div>
                  </div>
                  <div className="text-base max-w-xl p-5 md:inline-flex align-top text-start">
                    <div className="font-extrabold text-5xl float-left mx-3">
                      02.
                    </div>
                    <div>
                      At our core, we are passionate about connecting talented
                      professionals with businesses in need of their expertise.
                      We take pride in our rigorous screening process to ensure
                      that only the most qualified candidates join our talent
                      pool. Our team is committed to finding the perfect match
                      for our clients, and we are dedicated to helping them
                      achieve their goals.
                    </div>
                    <div>
                      <img
                        alt=""
                        src="img/card2.png"
                        className="max-w-[15rem] hidden md:flex"
                      />
                    </div>
                  </div>
                  <div className="text-base max-w-xl p-5 md:inline-flex align-top text-start">
                    <div className="font-extrabold text-5xl float-left mx-3">
                      03.
                    </div>
                    <div>
                      Our service focuses on dev and product talent, which means
                      that we hire top-tier talent and make them available for
                      our clients to use on their teams. This unique approach
                      allows businesses to easily and quickly scale up their
                      workforce without the burden of recruiting and hiring new
                      employees. We believe that this model is the future of
                      talent in the tech industry, and we are proud to be
                      leading the way.
                    </div>
                    <div>
                      <img
                        alt=""
                        src="img/card3.png"
                        className="max-w-[15rem] hidden md:flex"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="max-w-screen-lg mx-auto justify-center text-base flex flex-col flex-wrap">
              <div className="inline-flex text-gray-200">
                <div className="float-left uppercase p-5 text-3xl font-extrabold italic">
                  WHY US?
                </div>
                <div className="text-center text-xl p-5">
                  By hiring talent through our talent service, businesses can
                  benefit in{" "}
                  <span className="font-bold italic">so many ways</span>
                </div>
              </div>
              <div className="flex flex-col flex-wrap gap-4 md:grid-cols-3 mx-auto lg:flex-wrap md:gap-x-4 cursor-pointer text-center">
                <div className="about-card h-full bg-gray-50 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-gray-800">
                  <div className="my-3 uppercase font-bold text-emerald-800">
                    Access to top talent
                  </div>
                  Our talent service can provide clients with access to a pool
                  of pre-vetted and highly qualified candidates who have been
                  screened for their skills, experience, and cultural fit.
                </div>
                <div className="about-card h-full bg-gray-50 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-gray-800">
                  <div className="my-3 uppercase font-bold text-emerald-800">
                    Faster hiring process
                  </div>
                  Since our talent service has already done the initial
                  screening and vetting of candidates, clients can save time and
                  resources by bypassing the initial hiring process and jump
                  straight to interviewing and hiring.
                </div>
                <div className="about-card h-full bg-gray-50 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-gray-800">
                  <div className="my-3 uppercase font-bold text-emerald-800">
                    Flexibility
                  </div>
                  By hiring talent through our talent service, clients can scale
                  their teams up or down as needed without the long-term
                  commitment of hiring full-time employees. This allows them to
                  respond quickly to changing business needs.
                </div>
                <div className="about-card h-full bg-gray-50 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-gray-800">
                  <div className="my-3 uppercase font-bold text-emerald-800">
                    Cost savings
                  </div>
                  Hiring full-time employees can be costly in terms of salaries,
                  benefits, and other associated expenses. By hiring talent
                  through our talent service, clients can save on these costs
                  and only pay for the hours worked by the contracted talent.
                </div>
                <div className="about-card h-full bg-gray-50 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-gray-800">
                  <div className="my-3 uppercase font-bold text-emerald-800">
                    Reduced risk
                  </div>
                  Our talent service can assume some of the risk associated with
                  hiring by providing temporary or contract talent. This can
                  help clients avoid the costs and risks associated with making
                  a full-time hire that may not work out.
                </div>
              </div>
            </div>
          </div>
          <div className="m-10 max-w-3xl mx-auto text-xl md:text-2xl">
            Our success in the talent industry spans across different
            industries, including healthcare, finance, and education. We have
            helped companies of all sizes and shapes find the perfect talent for
            their needs, and we have earned a reputation for excellence in our
            field.
          </div>
          <div className="my-5 font-semibold">
            Thank you for considering our talent service for your business
            needs. We look forward to working with you!
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
