import Card from "./Card";
import services from "./data/services-data.js";
import "../style/services.css";
import { useEffect } from "react";
function Services() {
  useEffect(() => {

  });
  return (
    <>
      <div className="bg-ocean-100 text-gray-200 select-none text-center font-lato flex flex-col justify-center mx-auto justify-items-center">
        <div>
          <div className="bg-black py-8"></div>
          <section
            className="w-full overflow-hidden object-cover bg-black py-10"
          >
            <h2
              className="md:text-5xl text-2xl  font-extrabold uppercase py-12"
              style={{ opacity: 1 }} // set the opacity of the h2 element to 1
            >
              Our Hub of Excellent Services
            </h2>
          </section>
          <h2 className="text-center text-xl p-10">
            We understand that hiring the right talent is crucial to building
            top-notch products. That's why we offer a range of services tailored
            to all types of needs.
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 md:gap-16 max-w-screen-2xl pb-10 mx-auto">
          {services.map((item, index) => (
            <div key={index} className="max-w-xs text-start">
              <Card title={item.type} id={index} text={item.description} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
export default Services;
