import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import gsap from "gsap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function Navbar() {
  const [isClicked, setIsClicked] = useState(false);

  function openBar() {
    const mobileBar = document.querySelector(".mobileBar");
    setIsClicked(true);
    gsap.from(".mobileBar", { x: -100 });
    gsap.to(".mobileBar", { opacity: 1, x: 0 });
    mobileBar.classList.remove("hidden");
    mobileBar.classList.add("block");
  }
  function closeBar() {
    const mobileBar = document.querySelector(".mobileBar");
    setIsClicked(false);
    gsap.from(".mobileBar", { x: 0 });

    gsap.to(".mobileBar", { x: -100, opacity: 0 });
    mobileBar.classList.add("hidden");
    mobileBar.classList.remove("block");
  }
  useEffect(() => {
    const navbar = document.querySelector(".navbar");
    const bars = document.querySelector("#bars");

    const handleScroll = () => {
      if (window.scrollY > 100) {
        navbar.classList.add("navbar--scrolled");
        bars.style.color = "black";
        navbar.style.color = "black";
      } else {
        navbar.classList.remove("navbar--scrolled");
        navbar.style.color = "whitesmoke";
        bars.style.color = "whitesmoke";
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className="navbar flex flex-row align-text-bottom font-lato uppercase mb-16 py-4 text-gray-200 transition-colors ease-in-out delay-150">
        <div className="flex-grow">
          <h1 className="text-2xl pl-5 py-2">
            <Link className="cursor-pointer" to="/">
              ALDIRAC
            </Link>
          </h1>
        </div>
        <div className="font-lato py-2">
          <ul className="hidden md:flex gap-x-10">
            <li>
              <Link className="cursor-pointer" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="services">
                Services
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="about">
                About
              </Link>
            </li>
            <li>
              <Link className="cursor-pointer" to="contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        {!isClicked && (
          <button className="button block md:hidden" onClick={openBar}>
            <FontAwesomeIcon
              id="bars"
              icon={`fa-solid fa-bars`}
              size="2xl"
              style={{ color: "whitesmoke" }}
            />
          </button>
        )}
      </nav>
      <div>
        <nav className="mobileBar hidden min-h-screen bg-white  fixed z-10 pin-y bg-grey-lightest shadow-md w-full sm:w-1/3 lg:w-1/4 sidebar-inactive">
          <h1 className="text-lg p-6 bg-grey-lighter border-grey-light border-b text-grey-darkest">
            ALDIRAC
          </h1>
          <button className="button z-20 block sm:hidden" onClick={closeBar}>
            <FontAwesomeIcon icon={`fa-solid fa-xmark`} size="2xl" />
          </button>
          <ul className="text-lg">
            <li className="hover:bg-teal">
              <a
                href="/"
                className="block px-6 py-4 w-full text-grey-darkest hover:text-white text-left no-underline"
              >
                Home
              </a>
            </li>

            <li className="hover:bg-teal">
              <a
                href="about"
                className="block px-6 py-4 w-full text-grey-darkest hover:text-white text-left no-underline"
              >
                About
              </a>
            </li>
            <li className="hover:bg-teal">
              <a
                href="services"
                className="block px-6 py-4 w-full text-grey-darkest hover:text-white text-left no-underline"
              >
                Services
              </a>
            </li>

            <li className="hover:bg-teal">
              <a
                href="contact"
                className="block px-6 py-4 w-full text-grey-darkest hover:text-white text-left no-underline"
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
export default Navbar;
