import React, { useEffect } from "react";
import "../style/home.css";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Contact from "./Contact";

const SvgComponent = (props) => (
  <svg
    className="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    {...props}
  >
    <defs>
      <path
        id="a"
        d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
      />
    </defs>
    <g className="parallax">
      <use xlinkHref="#a" x={48} fill="rgba(255,255,255,0.7" />
      <use xlinkHref="#a" x={48} y={3} fill="rgba(255,255,255,0.5)" />
      <use xlinkHref="#a" x={48} y={5} fill="rgba(255,255,255,0.3)" />
      <use xlinkHref="#a" x={48} y={7} fill="#fff" />
    </g>
  </svg>
);

function Home() {
  const data = {
    industry: [
      { name: "CRM", icon: "users" },
      { name: "REAL ESTATE", icon: "building" },
      { name: "LMS", icon: "graduation-cap" },
      { name: "ERP", icon: "diagram-project" },
      { name: "AUTOMOTIVE", icon: "car" },
      { name: "BANKING", icon: "building-columns" },
      { name: "COMMUNICATIONS", icon: "tower-broadcast" },
      { name: "ENERGY", icon: "bolt" },
      { name: "RESEARCH", icon: "flask" },
      { name: "HEALTHCARE", icon: "hospital" },
      { name: "FINANCIAL", icon: "arrow-trend-up" },
      { name: "PHARMA", icon: "prescription" },
    ],
  };
  useEffect(() => {
    gsap.to(".navbar", {
      backgroundColor: "",
      color:"white"
    });
    gsap.from(".title", {
      y: -10,
      opacity: 0,
    });
    gsap.to(".title", {
      ease: "power4.out",
      y: 0,
      duration: 2,
      opacity: 1,
      stagger: {
        amount: 1,
      },
    });
  }, []);

  return (
    <>
      <div className="font-josefin">
        <div className="header text-center xl:pt-52 pt-24">
          <div className="flex flex-col text-6xl max-w-2xl mx-auto">
            <div className="title text-5xl uppercase">SCALABLE TEAMS</div>
            <div className="title text-xl py-3 uppercase">
              Our talent for your needs.{" "}
              <span className="font-bold">whenever, wherever.</span>
            </div>
          </div>
          <div></div>
          <SvgComponent />
        </div>
        <div className="max-w-screen-lg mx-auto justify-center ">
          <div className="font-lato grid grid-cols-1 md:grid-cols-3 mx-auto lg:flex-wrap md:gap-x-4 cursor-pointer pt-16 mb-12 text-center">
            <div className="h-28 md:h-32 mx-9 md:mx-2 bg-gray-200 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-2xl text-gray-800">
              Top talent, right at your fingertips
              <p className="lg:text-base text-sm text-gray-500">
                you only deserve the best
              </p>
            </div>
            <div className="h-28 md:h-32 mx-9 md:mx-2 bg-gray-200 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-2xl text-gray-800">
              Fexible options, self-service
              <p className="lg:text-base text-sm text-gray-500">
                complete control of cost
              </p>
            </div>
            <div className="h-28 md:h-32 mx-9 md:mx-2 bg-gray-200 shadow-xl opacity-90 active:opacity-100 hover:opacity-100 hover:shadow-2xl border-1 p-4 mt-4 rounded-lg text-2xl text-gray-800">
              Choose your customized package
              <p className="lg:text-base text-sm text-gray-500">
                options for all your needs
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 p-3 my-10 bg-gray-200 text-center justify-center">
          <div className="col text-gray-700 text-lg p-6 font-lato">
            At Aldirac, we understand the importance of finding the right talent
            for your team. Whether it's a temporary staff shortage or a need for
            new programmers.
            <br />{" "}
            <span className=" font-semibold">Aldirac has got you covered.</span>
            <br />
            With a track record of solving our clients' challenges, Aldirac has
            developed a proven model that delivers results.
          </div>
        </div>
        <div className="max-w-screen-lg flex flex-col items-center text-center justify-center mx-auto">
          <div className="grid px-2 md:grid-cols-4 grid-cols-2 md:gap-4 mx-5 gap-x-6">
            {data.industry.map((industryItem, index) => (
              <div key={index} className="inline-flex align-middle">
                <span className="h-10 w-10 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={`fa-solid fa-${industryItem.icon}`}
                    className="h-full w-full"
                    style={{ color: "gray" }}
                    pull="left"
                  />
                </span>
                <span className="text-emerald-500 md:text-base text-xs p-4 rounded-lg uppercase">
                  {industryItem.name}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6 pb-6 font-medium bg-gray-200  justify-center text-center text-lg px-10 font-lato">
          <div className="text-gray-700 text-lg p-6">
            With our flexible staffing solutions, you can scale your team up or
            down as needed, giving you the flexibility to meet the demands of
            any project.
            <br /> Contact us today to learn more about our staffing services
            and how we can help you build the best team for your next project.
          </div>
          <div className="inline gap-1 font-semibold">
            So why wait? Partner with Aldirac today and take your team to the{" "}
            <span className="text-emerald-800 italic">next level.</span>
          </div>
        </div>
      </div>
      <Contact isRoute={false} />
    </>
  );
}
export default Home;
