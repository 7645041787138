import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Services from "./components/Services";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBuildingColumns,
  faUsers,
  faGraduationCap,
  faTowerBroadcast,
  faHospital,
  faArrowTrendUp,
  faBolt,
  faPrescription,
  faBuilding,
  faCar,
  faDiagramProject,
  faFlask,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Contact from "./components/Contact";
import Home from "./components/Home";
import About from "./components/About";
library.add(
  faBuildingColumns,
  faUsers,
  faGraduationCap,
  faTowerBroadcast,
  faHospital,
  faArrowTrendUp,
  faBolt,
  faPrescription,
  faBuilding,
  faCar,
  faDiagramProject,
  faFlask,
  faBars,
  faXmark
);
const routes = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "about",
        element: <About />,
      },
    ],
  },
];
const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById("root"));
gsap.registerPlugin(ScrollTrigger);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<App />} />
  </React.StrictMode>
);
